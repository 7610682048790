import mainLogo from '../images/logo_goparts_main_color.png';
import squareLogo from '../images/g_main_logo_01_darkblue.png';
import mainLogoWhite from '../images/GoParts_Logo_24_principal_light.png';
import bannerLogoWhite from '../images/GoParts_Logo_24_reduzida_light.png';
import aboutUsBg from '../images/about_GoParts_bg_01.jpg';
import {SliderProducts} from '../Utilities/SliderProducts';

export const configData = {
    defaultStore: 'GoParts',
    defaultStoreLink: 'goparts.com.br',
    defaultContactEmail: 'contato@goparts.com.br',
    whatsappNumber: '41 99755 0248',
    CNPJ: '39.456.281/0001-27',
    CNPJname: 'Goparts Intermediacao de Negocios Ltda',
    CNPJaddress: 'R. Imac. Conceição, 1430 - Prado Velho, Curitiba - PR, 80215-182',
    launchDate: '20/01/2025',

    multiContact: {},
    searchByPlate: true,

    coupons: [
        // {
        //     coupon: '10PORCENTO',
        //     discountPrice: 0,
        //     discountPercent: 0.1,
        //     freeShipping: false,
        // },
        // {
        //     coupon: '10REAIS',
        //     discountPrice: 10,
        //     discountPercent: 0,
        //     freeShipping: false,
        // },
        // {
        //     coupon: 'FRETEGRATIS',
        //     discountPrice: 0,
        //     discountPercent: 0,
        //     freeShipping: true,
        // },
    ],

    brands: [
        'audi',
        'bmw',
        'byd',
        // 'chery',
        'chevrolet',
        'citroen',
        'fiat',
        'ford',
        // 'harley-davidson',
        'honda',
        'honda motos',
        'hyundai',
        'jeep',
        'kia',
        // 'mercedes-benz',
        'mini',
        'mitsubishi',
        'nissan',
        'peugeot',
        'renault',
        'toyota',
        'volkswagen',
        'volvo',
        // 'yamaha',
    ],

    homologBrands: [
        'motorcraft',
        'motrio',
        'acdelco',
        'mopar',
        'bproauto',
    ],

    noVehicle: false,

    storeSellersWarehouses: [
        "Globo Veículos Alto da XV",
        "Globo Nissan Alto da XV",
        "GLOBO JEEP CURITIBA",
        "Nissul Pelotas",
        "NISSUL GALA JEEP BAGÉ",
        "Iesa Ipiranga",
        "Itavema France Pinheiros",
        "ITVA FIAT NOVA IGUACU",
        "TOYOTA BOTAFOGO",
        "Iesa NISSAN LOG",
        "ITVA HYUNDAI CAMPO GRANDE",
        "NISSUL GALA HYUNDAI PELOTAS",
        "SAVOL VW CENTRO",
        "REVEMAR FIAT BELEM",
        "UNIQUE ANANINDEUA",
        "TROPICAL SÃO LUIS",
        "TROPICAL IMPERATRIZ",
        "Iesa VOLVO Azenha",
        "ITVA VOLVO VILA OLIMPIA",
        "BELCAR ASUKA",
        "Du Nort Belém - Marco",
        "IESA MOPAR",
        "SAVOL TOYOTA",
        "Iesa BMW NH",
        "BELCAR VW",
        "BELCAR FIAT",
        "REVEMAR VW",
        "SAVOL PSA",
        "SAVOL FIAT",
        "SAVOL KIA",
        "FENIX BELEM",
        "DISCAUTOL VW CARANDA",
        "REVEMAR VW PARAUAPEBAS",
        "REVEMAR FIAT - SÃO LUIS",
        "REVEMAR PSA BELEM",
        "FENIX PARAUAPEBAS",
        "TOYOTA GASTÃO VIDIGAL",
        "Itavema France Guarulhos",
        "Du Nort São Luís",
        "TROPICAL ANANINDEUA",
        "TROPICAL DOCA",
        "VECOL VW",
        "TEXAS",
        "Du Nort Marabá",
        "Du Nort Parauapebas",
        "TROPICAL MARABA",
        "TERRA SANTA",
        "Fórmula Curitiba - Parolin",
        "FABERGE KIA",
        "FABERGE VOLVO MOGI",
        "FABERGE PSA OFICINA",
        "RENAULT FABERGE",
        "FIAT FABERGE",
        "FABERGE AUDI MOGI",
        "GLOBO RENAULT SC",
        "GLOBO JEEP SC",
        "GLOBO FORD SC",
        "VIGORITO VW ATACADO",
        "VIGORITO NISSAN ATACADO",
        "VIGORITO KIA",
        "VIGORITO GM ATACADO",
        // "ITAVEMA JAPAN GASTAO VIDIGAL", //Itavema Nissan Off
        "BARI PONTA GROSSA HYUNDAI",
        "BARIGUI HYUNDAI SC",
        "BARIGUI NISSAN",
        "GLOBO FIAT SC",
        "NIX NISSAN BLUMENAU",
        "NIX NISSAN JOINVILLE",
        "UMUARAMA FIAT ARAGUAINA",
        "UMUARAMA FIAT PARAUAPEBAS",
        "UMUARAMA FIAT REDENCAO",
        "UMUARAMA TOYOTA ARAGUAINA",
        "UMUARAMA TOYOTA IMPERATRIZ",
        "UMUARAMA TOYOTA RIO VERDE",
        "UMUARAMA VW ARAGUAINA",
        "UMUARAMA VW ITUMBIARA",
        "UMUARAMA KIA",
        "UMUARAMA CITROEN RIO VERDE",
        "UMUARAMA TOYOTA CALDAS NOVAS",
        "UMUARAMA TOYOTA MINEIROS",
        "UMUARAMA TOYOTA ITUMBIARA",
        "RECREIO BH VW",
        "RECREIO RJ VW",
        'SULPAR SGA TOYOTA - HAUER',
        'SULPAR SGA TOYOTA - NITEROI',
        'VITORIAWAGEN SERRA',
        'UMUARAMA HARLEY GOIANIA',
      
        // 'VIGORITO VW SAO BERNARDO',
        // 'VIGORITO VW MAUA',
        // 'VIGORITO VW VILA MARIANA',
        // 'VIGORITO NISSAN PENHA',
        // 'VIGORITO NISSAN GUARULHOS',
        // 'VIGORITO KIA ARICANDUVA',
      
        'DUVEL - VEICULOS CENTRO',
        "LUSON VW",
        "LUSON VW TARUMA",
        "Iesa NISSAN Canoas",
        "Iesa NISSAN Gravataí",
        "Iesa NISSAN NH",
        "Iesa NISSAN Osório",
        "Iesa NISSAN Tarso",
        "Iesa NISSAN Wenceslau",
        "Iesa JEEP Cachoeirinha",
        // "Iesa HARLEY Poa",
        "Carmo Montes Claros",
        "Open Veículos Cascavel",
        "VETOR HYUNDAI CASCAVEL",
        "BRIZZA MITSUBISHI CASCAVEL",
        "SAINT-TROPEZ PEUGEOT SANTOS",
        "AKTA KIA",
        "EUROKRAFT VW NOVA FRIBURGO",
        "BARIGUI FIAT BLUMENAU",
        "BARIGUI TORRES UBERADA",
        "BARIGUI BLUSA VW",
        "BARIGUI CABRAL VW",
        "Fórmula Londrina",
        'APIA VW',
        "ITAVEMA BYD NOVA IGUACU",
        "AUDI CENTER TERESINA",
        // 'ALTA VW SANTANA', //desativado em 06/12/2024, falta preparo lado concessionária
        'ORLY FIAT BETIM',
        // "EAGLE JEEP RECREIO RJ", //desativado em 05/12/2024, cliente falta braço (avisará para reativar)
        "BARIGUI BMW MARINGÁ",
        "BARIGUI KIA CAMPINA SIQUEIRA",
        "BARIGUI CITROEN PARQUE",
        "Barigui FORD Estoques",
        "BARIGUI BYD - TARUMA",
        "BARIGUI VW - SAO JOSE (MATRIZ)",
        "BARIGUI AUDI - LONDRINA",
        "CANADA GM",
        "UMUARAMA VW CATALAO",
        "JAPAN MOTOS MATRIZ",
        "JAPAN NISSAN TERESINA",
        "Via Paris Bairro dos Noivos",
        "ALEMANHA VW",
        "VIVA VW MACEIO",
        "NISSUL GALA JEEP PELOTAS",
        "BARIGUI PEUGEOT XV", //Concessionária Peugeot - Curitiba PR
        "BARIGUI AUDI - LONDRINA", //Concessionária Audi - Londrina PR
        "ESTILO VW - POUSO ALEGRE", //Concessionária Volkswagen - Pouso Alegre MG
        "LOVAT HYUNDAI - LONDRINA", //Concessionária Hyundai - Londrina PR
        "PROVENCE PEUGEOT-CITROEN", //Concessionária PSA - Ponta Grossa PR
        "BARIGUI JEEP - TORRES", //"Concessionária Jeep - Curitiba PR"
        "GLOBO NISSAN SC", //Concessionária Nissan - Florianópolis SC
        "UMUARAMA HARLEY UBERLANDIA", //Concessionária Harley Davidson - Uberlândia MG
        "SAIKON HONDA GUARAPUAVA", //sem estoque para testar compra
        "UMUARAMA KTM GOIANIA",
        "FARIA SÃO PAULO",
        "DOKAR VW - PINDAMONHANGABA",
        "RECREIO VW MG - MILA",
        "COMERI VW",
        "Barigui FORD Maringá",
        "INTERCONTINENTAL POA",
        "INTERCONTINENTAL CAXIAS",
      
        //testar / aguardar
      
        // "FABERGE HYUNDAI", //Concessionária Hyundai - Mogi Das Cruzes SP
        // "K-NORTE KIA SOROCABA", //Concessionária Kia - SOROCABA SP

        "MECAUTOR VW - SANTO ANGELO",
        "DISCAUTOL PSA - AHO FRANCE",
        "BREITKOPF VW BLUMENAU",
        "MAZAM MOTORS HYUNDAI SETE LAGOAS",
        "TRIUMPH UBERLANDIA - UMUARAMA",

        "PAMPEIRO VW - SANTA MARIA",
        "AUDI CENTER - ALTO DA XV",
        "BEVEL VW - MARAU",
        "Gala Canoas",
        "Iesa NISSAN Wenceslau",
        "BELCAR MOTOS INDEPENDENCIA",
    ],

    disabledSellers: [
        "FABERGE HYUNDAI", //Concessionária Hyundai - Mogi Das Cruzes SP
        "K-NORTE KIA SOROCABA", //Concessionária Kia - SOROCABA SP
        "EAGLE JEEP RECREIO RJ", //desativado em 05/12/2024, cliente falta braço (avisará para reativar)
        'ALTA VW SANTANA', //desativado em 06/12/2024, falta preparo lado concessionária
        "Iesa HARLEY Poa",
        'VIGORITO VW SAO BERNARDO',
        'VIGORITO VW MAUA',
        'VIGORITO VW VILA MARIANA',
        'VIGORITO NISSAN PENHA',
        'VIGORITO NISSAN GUARULHOS',
        'VIGORITO KIA ARICANDUVA',
        "ITAVEMA JAPAN GASTAO VIDIGAL", //Itavema Nissan Off
        "BARAO 3030", //Concessionária Kia - Belo Horizonte MG
        "UMUARAMA TOYOTA BALSAS",
        "UMUARAMA VW PALMAS",
    ],

    endYear: 2025,
    startYear: 1996,

    payment: {
        maxParcels: 6,
        checkoutParcels: {
            0: 1,
            200: 2,
            300: 3,
            600: 4,
            800: 5,
            1000: 6,
        }
    },

    customShipping: {
        deliveryTime: 1,
    },

    productSlider: [
        {
            name: 'Kits',
            img: 'tabKits',
            products: SliderProducts.Kits
        },
        {
            name: 'Óleos',
            img: 'tabOleos',
            products: SliderProducts.Oleos
        },
        {
            name: 'Filtros',
            img: 'tabFiltros',
            products: SliderProducts.Filtros
        },
        {
            name: 'Iluminação',
            img: 'tabLuzes',
            products: SliderProducts.Luzes
        },
        {
            name: 'Freios',
            img: 'tabFreios',
            products: SliderProducts.Freios
        },
        {
            name: 'Acessórios',
            img: 'tabAcessorios',
            products: SliderProducts.Acessorios
            },
        {
            name: 'Roupas',
            img: 'tabRoupas',
            products: SliderProducts.Roupas
        }
    ]
}

export const contentData = {
    mainLogo: mainLogo,
    squareLogo: squareLogo,
    mainLogoWhite: mainLogoWhite,
    bannerLogoWhite: bannerLogoWhite,

    aboutUsBg: aboutUsBg,

    aboutUs: {
        title: 'Quem somos',
        content: [
            'Nosso objetivo: entregar a melhor experiência de compra de autopeças',
            'Com a mais avançada inteligência de compatibilidade de autopeças do Brasil, você compra o produto certo para o seu veículo com tranquilidade.'
        ],
        link: 'https://paraempresas.goparts.com.br/',
        linkCta: 'GoParts para empresas',
    },

    socialMedia: {
        Instagram: 'https://www.instagram.com/gopartsbrasil/',
        Facebook: 'https://www.facebook.com/gopartsbrasil',
        LinkedIn: 'https://www.linkedin.com/company/78339440/admin/dashboard/',
        Youtube: '',
        Twitter: '',
    },

    footerContent: [
        'Atenção: compras e atendimento somente através deste site; não atendemos via loja física.',
    ],
}
